import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startMatching } from "../../../actions/applicationActions";
import { useHistory } from "react-router-dom";
import axios from "axios";

const AdminProfileActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const buttonRowStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px", // Space between buttons
  };

  const dashButtonStyles = {
    flex: "1 1 45%", // Ensures two buttons per row on larger screens
  };

  const marginBottomStyle = {
    marginBottom: "1rem",
  };

  const marginTopStyle = {
    marginTop: "1rem",
  };

  //call /api/matching
  // axios({
  //   url: `/api/company/downloadcompanies`,
  //   method: "GET",
  //   responseType: "blob",
  // }).then((response) => {
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", `Registered-Companies.xlsx`);
  //   document.body.appendChild(link);
  //   link.click();
  // });
  // const handleGetMatching = () => {
  //   history.push("/matching", { action: "getMatching" }); // Redirect using push
  // };

  // const handleStartMatching = () => {
  //   history.push("/matching", { action: "startMatching" }); // Redirect using push
  // };

  const handleSendEmail = () => {
    axios
      .get("/api/company/sendEmail")
      .then((response) => {
        console.log("Email sent");
      })
      .catch((error) => {
        console.log("Error sending email: " + error);
      });
  };

  const handleSendingAuthorizedEmails = () => {
    axios
      .post("/api/forms/sendAuthorizedEmails")
      .then((response) => {
        console.log("Credential Emails sent");
      })
      .catch((error) => {
        console.log("Error sending credential emails: " + error);
      });
  };

  return (
    <div style={marginBottomStyle}>
      <h6>Student & Company Information:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/view-student-profiles">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Student Profiles
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-company-profiles">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Company Info
            </Button>
          </Link>
        </div>
      </div>

      <h6 style={marginTopStyle}>Matching:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/matching">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Matching Results
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/startmatching">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Start Matching
            </Button>
          </Link>
        </div>
      </div>

      <h6 style={marginTopStyle}>Upload Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/upload-poster">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Upload Poster
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/upload-image">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Upload Image
            </Button>
          </Link>
        </div>
      </div>
      <h6 style={marginTopStyle}>Send Authorized Emails:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
            onClick={handleSendingAuthorizedEmails}
          >
            Send Email to Students & Companies
          </Button>
        </div>
      </div>
      <h6 style={marginTopStyle}>Image and Poster Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/view-images">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Images
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-posters">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Posters
            </Button>
          </Link>
        </div>
      </div>

      <h6 style={marginTopStyle}>Team Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/create-team">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Create Team
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-teams">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View All Teams
            </Button>
          </Link>
        </div>
      </div>

      <h6 style={marginTopStyle}>Test Upload:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/test-upload-admin">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Test Upload Admin
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileActions;
