import { jwtDecode } from "jwt-decode";

export const getTokenFromCookie = () => {
  const cookies = document.cookie.split("; ");
  const jwtCookie = cookies.find((cookie) => cookie.startsWith("jwt2="));
  return jwtCookie ? jwtCookie.split("=")[1] : null;
};

export const isTokenValid = () => {
  const token = getTokenFromCookie();
  console.log("checkin token", token);
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 > Date.now(); // Check if token has expired
  } catch (err) {
    console.error("Invalid token:", err);
    return false;
  }
};
