import React from "react";
import { Route, Redirect } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const getTokenFromCookie = () => {
    const cookies = document.cookie.split("; "); // Split cookies into an array
    console.log("Cookies:", cookies); // Debugging the cookies
    const jwtCookie = cookies.find((cookie) => cookie.startsWith("jwt2=")); // Find the `jwt` cookie
    return jwtCookie ? jwtCookie.split("=")[1] : null; // Extract the token value
  };

  const isAuthenticated = () => {
    const token = getTokenFromCookie();
    console.log("Extracted token:", token); // Debugging the extracted token
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      console.log("Decoded token:", decoded); // Debugging the decoded token
      return decoded.exp * 1000 > Date.now(); // Check token expiration
    } catch (err) {
      console.error("Invalid token:", err);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
